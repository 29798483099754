import React from "react";
import { HomeBackToTop, HomeSignUp } from "../../components";

const SignUp = () => {

    return(
        <div>
            <HomeSignUp />
            <HomeBackToTop />
        </div>
    );
};

export default SignUp;